/* ACCORDIAN CSS */
.accordion {
  max-width: 800px;
  margin: 2rem auto;
  width: 800px;
  color: white;
  border-top: 1px solid #fff;
}
.react-multiple-carousel__arrow--left {
  left: -10px !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.react-multiple-carousel__arrow::before {
  color: #ffffff !important;
  font-size: 40px !important;
  font-weight: bold !important;
}

.react-multiple-carousel__arrow--right {
  right: -10px !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
}

.accordion-title:hover {
  background-color: transparent;
}

.accordion-title,
.accordion-content {
  padding: 1.5rem;
  text-align: left;
}

.accordion-content {
  background-color: transparent;
}

/* CARD HOVER CSS */
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #01ffa4, #3468df);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.card1 {
  width: 30%;
  margin-left: 62.5%;
}
.card2 {
  width: 32.5%;
  margin-left: 65%;
}
.card {
  width: 600px;
  margin-left: 70%;
}

.tabs {
  margin: 40px 0 32px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 24px;
}

.tab {
  font-size: 20px;
  margin: 0 4px;
  line-height: 24px;
  color: #bcbcbc;
  font-weight: 500;
  width: max-content;
  padding-bottom: 12px;
  cursor: pointer;
}

.tab.active {
  color: rgb(255, 255, 255);
  font: bolder;
  margin: 0;
  font-size: 22px;
  line-height: 24px;
  border-bottom: 3px solid rgb(255, 255, 255);
}

.hero {
  width: 100%;
  height: 100%;
  position: relative;
  display: none;
  margin-top: 32px !important;
}

.hero.img-active {
  display: block;
}

.header-img {
  /* width: 100%;
  height: 100%; */
  position: relative;
  z-index: -10;
  /* background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url("../../images/Header.png") no-repeat; */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.sections-img {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-image: url("../../images/Sectionsbg.png");
}

.landing-bg {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.hero-bg {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.nav.colorChange {
  background-color: #00000096;
}

.comp{
  /* display: block; */
  height: 75vh;
}

.comp::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.comp {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.comp.componentHide{
  display: none !important;
}

.section-animate {
  animation: fadeInAnimation ease 10s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
}

.dot.active {
  background: rgba(255, 255, 255, 0.8);
}

.text-shadow {
  color: white;

  --x-offset: -0.125em;
  --y-offset: -2px;
  --stroke: 0em;
  --background-color: #0d1c2c;
  --stroke-color: #e7e7e7;

  text-shadow: var(--x-offset) var(--y-offset) 1px var(--background-color),
    calc(var(--x-offset) - var(--stroke)) calc(var(--y-offset) + var(--stroke))
      0px var(--stroke-color);
}

.text-help {
  margin-top: -20px;
}

.news {
  height: 500px;
}
.news-img {
  height: 350px;
  width: 400px;
}

.drop svg path{
  fill: white !important;
}

.drop2 svg path{
  stroke: white !important;
}

.drop:hover svg path{
  fill: #8BEEBE !important;
}


.drop2:hover svg path{
  stroke: #8BEEBE  !important;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .iPhone {
    background-color: rgba(82, 82, 82, 0.827);
  } 
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1280px) {
  .card1 {
    width: 600px;
    margin-right: 20% !important;
    margin-left: 10% !important;
  }
  .card2 {
    width: 600px;
    margin-right: 20% !important;
    margin-left: 10% !important;
  }
}

@media screen and (max-width: 1280px) {
  .card {
    width: 600px;
    margin-left: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .accordion {
    width: 700px;
  }
  .card {
    width: 500px;
    margin-right: 25% !important;
    margin-left: 20% !important;
  }
  /* .card1 {
    width: 500px;
    margin-right: 25% !important;
    margin-left: 20% !important;
  } */
  .card2 {
    /* width: 500px;
    margin-right: 25% !important;
    margin-left: 20% !important; */
    display: none;
  }
  .comp.componentHide{
    display: unset !important;
  }
}

@media screen and (max-width: 700px) {
  .accordion {
    width: 400px;
  }
  .card {
    margin-left: 0 !important;
  }
  /* .card1 {
    width: 60%;
    margin-left: 0 !important;
  } */
}

@media screen and (max-width: 850px) {
  .comp{
    height: 50vh;
  }
  .sections-img {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    background-image: url("../../images/Bg.png");
  }
}

@media screen and (max-width: 789px) and (min-width: 787px) {
  .comp{
    height: 80vh !important;
  }
  .news-img{
    height: 1000px !important;
  }
}

@media screen and (max-width: 767px) {
  .nav {
    position: unset;
  }
  .card {
    margin-left: 20% !important;
  }
  .card1 {
    margin-left: 5% !important;
  }
  .text-help {
    margin-top: 0px !important;
  }
  .card2 {
    display: none;
  }
  .comp.componentHide{
    display: unset !important;
  }
  .comp{
    height: 50vh ;
  }
  .step-img{
    display: none;
  }
  
  
}


/*  */

@media screen and (max-width: 450px) {
  .accordion {
    width: 250px;
  }
  .numbers{
    margin-left: 0 !important;
  }
  .accordion-title {
    font-size: 12px;
  }
  .accordion-title,
  .accordion-content {
    padding: 1rem;
    text-align: left;
    font-size: 12px;
  }

  .card {
    width: 350px !important;
    margin-left: 10% !important;
  }
  .card1 {
    width: 330px !important;
    margin-left: 7.5% !important;
  }
  .news {
    width: 207.77px !important;
height: 300.75px !important;
  }
  .news-img {
    width: 207.77px !important;
height: 200.79px !important;
  }
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
  }
  
  .dot.active {
    background: rgba(255, 255, 255, 0.8);
  }
  .tabs {
    justify-content: unset;
    flex-wrap: unset;
    overflow-x: scroll;
    margin-top: 24px !important;
    margin-left: 20px;
  }
  .tab {
    font-size: unset;
    margin: 0 0px;
    line-height: 24px;
    color: #bcbcbc;
    font-weight: 500;
    width: unset !important;
    padding-bottom: 0 !important;
    white-space: nowrap;
  }
  .tab.active {
    color: rgb(255, 255, 255);
    font: bolder;
    margin: 0;
    font-size: unset;
    line-height: 24px;
    white-space: nowrap;
    border-bottom: 3px solid rgb(255, 255, 255);
  }
  .hero {
    width: 85% !important;
    margin-left: 7.5% !important;
    /* height: 100%; */
    position: relative;
    display: none;
    margin-top: 32px !important;
  }
  .small-text{
    font-size: 0.5rem;
  }
  .comp{
    height: 70vh;
  }
  .step-img{
    display: none;
  }
  .react-multiple-carousel__arrow--left {
    display: none;
  }
  
  .react-multiple-carousel__arrow--right {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .card {
    width: 345px !important;
    margin-left: 5% !important;
  }
}

@media screen and (max-width: 350px) {
  .card {
    width: 285px !important;
    margin-left: 5% !important;
  }
  .card1 {
    width: 285px !important;
    margin-left: 5% !important;
  }
}


